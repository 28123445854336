import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatPrice',
})
export class FormatPricePipe implements PipeTransform {
  transform(price: number | undefined): string {
    return price !== undefined ? `${price / 100} €`.replace('.', ',') : '';
  }
}
