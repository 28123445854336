import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConsumptionData, ICardsHttpService } from '../interfaces';
import { firstValueFrom } from 'rxjs';
import { CardCancellationResponseBack, CardConsumptionResponseBack, CardResponseBack, SessionsResponseBack } from '../interfaces/types';

@Injectable()
export class CardsHttpService implements ICardsHttpService {
  constructor(protected httpClient: HttpClient) {}

  getCard(country: string, numCard: string): Promise<CardResponseBack> {
    return firstValueFrom(this.httpClient.get<CardResponseBack>(`/cards/${numCard}`, { params: { country, photoEnabled: true } }));
  }

  consumeCard(country: string, numCard: string, data: ConsumptionData): Promise<CardConsumptionResponseBack> {
    const headers = new HttpHeaders().set('Skip-Error-Interceptor', 'true');

    return firstValueFrom(
      this.httpClient.post<CardConsumptionResponseBack>(`/cards/${numCard}/consume`, data, {
        params: { country, transactionChannel: data.transactionChannel },
        headers: headers,
      }),
    );
  }

  getCardSessions(country: string, numCard: string): Promise<SessionsResponseBack> {
    return firstValueFrom(this.httpClient.get<SessionsResponseBack>(`/cards/${numCard}/sessions`, { params: { country } }));
  }

  cancelCardOperation(
    country: string,
    numCard: string,
    consumeTransactionId: number,
    transactionChannel: string,
  ): Promise<CardCancellationResponseBack> {
    const headers = new HttpHeaders().set('Skip-Error-Interceptor', 'true');

    return firstValueFrom(
      this.httpClient.post<CardCancellationResponseBack>(
        `/cards/${numCard}/cancel`,
        {},
        {
          params: { country, consumeTransactionId, transactionChannel },
          headers: headers,
        },
      ),
    );
  }
}
