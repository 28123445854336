import { Observable } from 'rxjs';
import { ReportingPartnersModel, ReportingTransactionsModel } from '../../../models';

export abstract class IReportingService {
  abstract getReportingPartners(
    country: string,
    startDate: string,
    endDate: string,
    theaterCode?: string,
  ): Observable<ReportingPartnersModel>;
  abstract getReportingTransactions(
    country: string,
    startDate: string,
    endDate: string,
    theaterCode?: string,
  ): Promise<ReportingTransactionsModel[]>;
}
