import { GroupeParametres } from '@pathe-payment/common';
import { Roles } from '@pathe-payment/core';

export enum Parametres {
  PAYS,
  LANGUE,
  ROLE,
  TYPE_RECHERCHE,
  TYPE_RAPPORT,
}

export enum Groupes {
  PAYS,
  LANGUE,
  ROLE,
  TYPE_RECHERCHE,
  TYPE_RAPPORT,
}

export const allAvailableCountries = [
  { value: 'FR', libelle: 'common.pays.fr' },
  { value: 'NL', libelle: 'common.pays.nl' },
  { value: 'BE', libelle: 'common.pays.be' },
  { value: 'CH', libelle: 'common.pays.ch' },
];

export const groupes: GroupeParametres[] = [
  {
    id: Groupes.PAYS,
    libelle: 'pages.parametres.parametres.pays',
    parametres: [
      {
        id: Parametres.PAYS,
        libelle: 'pages.parametres.parametres.pays',
        default: 'FR',
        values: [{ value: 'FR', libelle: 'common.pays.fr' }],
      },
    ],
  },
  {
    id: Groupes.LANGUE,
    libelle: 'pages.parametres.parametres.langue',
    parametres: [
      {
        id: Parametres.LANGUE,
        libelle: 'pages.parametres.parametres.langue',
        default: 'fr',
        values: [
          { value: 'fr', libelle: 'common.langues.fr' },
          { value: 'en', libelle: 'common.langues.en' },
        ],
      },
    ],
  },
  {
    id: Groupes.ROLE,
    libelle: 'pages.parametres.parametres.role',
    parametres: [
      {
        id: Parametres.ROLE,
        libelle: 'pages.parametres.parametres.role',
        default: '',
        values: [
          { value: '', libelle: 'common.role.defaut' },
          { value: 'ROLE_ADMIN', libelle: 'common.role.admin' },
          { value: 'ROLE_HEADQUARTER', libelle: 'common.role.headquarter' },
          { value: 'ROLE_SUPERVISOR_THEATER', libelle: 'common.role.supervisor' },
          { value: 'ROLE_SQUAD', libelle: 'common.role.squad' },
          { value: 'ROLE_PARTNER', libelle: 'common.role.partner' },
          { value: 'ROLE_POS', libelle: 'common.role.pos' },
        ],
      },
    ],
  },
  {
    id: Groupes.TYPE_RECHERCHE,
    libelle: 'pages.parametres.parametres.type_recherche.titre',
    parametres: [
      {
        id: Parametres.TYPE_RECHERCHE,
        libelle: 'pages.parametres.parametres.type_recherche.titre',
        default: 'code',
        values: [
          { value: 'code', libelle: 'pages.parametres.parametres.type_recherche.code' },
          { value: 'serie', libelle: 'pages.parametres.parametres.type_recherche.serie' },
        ],
      },
    ],
    roles: ['ROLE_ADMIN', 'ROLE_HEADQUARTER', 'ROLE_SQUAD'],
  },
];
