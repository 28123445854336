import { Injectable } from '@angular/core';
import { IReportingService } from '../interfaces';
import {
  ReportingPartnersModel,
  ReportingPartnersTransformer,
  ReportingTransactionsModel,
  ReportingTransactionsTransformer,
} from '../../../models';
import { IReportingHttpService } from '../../http';
import { map, Observable } from 'rxjs';

@Injectable()
export class ReportingService implements IReportingService {
  constructor(private monitoringHttpService: IReportingHttpService) {}

  getReportingPartners(country: string, startDate: string, endDate: string, theaterCode?: string): Observable<ReportingPartnersModel> {
    return this.monitoringHttpService
      .getReportingPartners(country, startDate, endDate, theaterCode)
      .pipe(map((reporting) => ReportingPartnersTransformer.parse(reporting)));
  }

  async getReportingTransactions(
    country: string,
    startDate: string,
    endDate: string,
    theaterCode?: string,
  ): Promise<ReportingTransactionsModel[]> {
    const reportingTransactions = await this.monitoringHttpService.getReportingTransactions(country, startDate, endDate, theaterCode);

    return reportingTransactions.transactions.map((transaction) => ReportingTransactionsTransformer.parse(transaction));
  }
}
