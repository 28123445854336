<div class="toolbar">
  <ng-container *pclRoleAccess="[Roles.ROLE_HEADQUARTER, Roles.ROLE_ADMIN, Roles.ROLE_SQUAD]">
    <img
      [src]="typeRecherche === 'serie' ? 'assets/svg/icons/QRcode_noir.svg' : 'assets/svg/icons/Search.svg'"
      *pclDisplayForRoute="['/']"
      (click)="toggleRechercheSerieCode(typeRecherche)"
    />
  </ng-container>

  <ng-container *ngIf="(typeRapport$ | async) as typeRapport">
    <div class="reporting"></div>
    <img
      [src]="typeRapport === 'transactions' ? 'assets/svg/icons/Avance_actif.svg' : 'assets/svg/icons/Avance.svg'"
      *pclDisplayForRoute="['/reporting', '/reporting-transactions']"
      (click)="toggleRapportTransactionPartenaire(typeRapport)"
      class="reporting"
    />
  </ng-container>

  <img
    [routerLink]='["parametres"]'
    routerLinkActive
    #rla="routerLinkActive"
    src="assets/svg/icons/Parametres.svg"
    [alt]="'common.alt.parametres' | translate "
  />
</div>
